/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
<template>
  <b-overlay
    :show="loading"
    opacity="0.3"
    variant="transparent"
    spinner-variant="primary"
    spinner-type="border"
    rounded="sm"
    style="min-height: 200px;"
  >
    <div v-if="trip">
      <b-card>
        <b-card-title>
          <p class="d-inline-block">
            <b>
              Trip: #{{ trip._id }}
            </b>
          </p>

          <b-button
            variant="primary"
            size="md"
            class="btn-icon float-right"
            @click="editItem"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
        </b-card-title>
        <b-card-text>
          <b-row>
            <b-col cols="3">
              <p>Destinations: <b><span v-safe-html="trip.destinations" /></b></p>
              <p>Trip Duration: <b>{{ trip.tripDuration }}</b></p>
              <p>Arrival Date: <b>{{ trip.arrivalDate }}</b></p>
              <p>Vendor-Agent: <b>{{ trip.vendor ? trip.vendor.name : '' }}</b></p>
              <p>Domain: <b>{{ trip.domain ? trip.domain.name : '' }} ({{ trip.domain ? trip.domain.url : '' }}) </b> </p>
              <p>Confirmed:
                <b-badge :variant="booleanVariant(trip.confirmed)">
                  <span class="">
                    <b>
                      {{ trip.confirmed }}
                    </b>
                  </span>
                </b-badge>
              </p>
              <p>isV2:
                <b-badge :variant="booleanVariant(trip.v2)">
                  <span class="">
                    <b>
                      {{ trip.v2 }}
                    </b>
                  </span>
                </b-badge>
              </p>
              <p>Appointment:
                <b-badge :variant="booleanVariant(trip.appointment)">
                  <span class="">
                    <b>
                      {{ trip.appointment }}
                    </b>
                  </span>
                </b-badge>
              </p>
              <p>Country: <b>{{ trip.country }}</b></p>
              <p>Custom Destination: <b>{{ trip.customDestination }}</b></p>
              <p>Daily VIlla Rate: <b>{{ trip.dailyVillaRate }}</b></p>
              <p>Rooms: <b>{{ trip.rooms }}</b></p>
            </b-col>

            <b-col cols="3">
              <p>FullName: <b>{{ trip.fullName }}</b></p>
              <p>Email: <b>{{ trip.email }}</b></p>
              <p>Phone: ({{ trip.phoneCode }}) <b>{{ trip.phone }}</b></p>
              <p>Adults: <b>{{ trip.adults }}</b></p>
              <p>Children: <b>{{ trip.children }}</b></p>
              <p>Age Of Children: <b>{{ trip.ageOfChildren }}</b></p>
              <p>Accommodation Category : <b>{{ trip.accommodationCategory }}</b></p>
              <p>Accommodation Type: <b>{{ trip.accommodationType }}</b></p>
              <p>Boat Type: <b>{{ trip.boatType }}</b></p>
              <p>Yacht Cabins: <b>{{ trip.yachtCabins }}</b></p>
            </b-col>

            <b-col cols="3">
              <p>Gastronomy: <b>{{ trip.gastronomy }}</b> / 10</p>
              <p>LocalLife: <b>{{ trip.localLife }}</b> / 10</p>
              <p>Adventure: <b>{{ trip.adventure }}</b> / 10</p>
              <p>Car Rental: <b>{{ trip.carRental ? 'Yes' : 'No' }}</b> </p>
              <p>Sightseeing Attractions: <b>{{ trip.sightseeingAttractions }}</b> / 10 </p>
              <p>Airport Port Transfer: <b>{{ trip.airportPortTransfer ? 'Yes' : 'No' }}</b> </p>
              <p>Is Budget Flexible: <b>{{ trip.isBudgetFlexible ? 'Yes' : 'No' }}</b> </p>
              <p>perPersonBudget: <b>{{ trip.perPersonBudget }} € </b> </p>
              <p>Daily Yacht Rate: <b>{{ trip.dailyYachtRate }}</b></p>
              <p>Needs Skipper: <b>{{ trip.needsSkipper }}</b></p>
            </b-col>

            <b-col cols="3">
              <p>Sail holidays: <b>{{ trip.sailExperience }}</b> </p>
              <p>Yacht holidays: <b>{{ trip.yachtExperience }}</b> </p>
              <p>Status: <b>{{ trip.status }}</b> </p>
              <p>Terms: <b>{{ trip.terms }}</b> </p>
              <p>Newsletter: <b>{{ trip.newsletter }}</b> </p>
              <p>Comments: <b>{{ trip.comments }}</b> </p>
              <p>Created At: <b>{{ trip.createdAt }}</b> </p>
              <p>Trip Type: <b>{{ trip.tripType }}</b></p>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>

      <div class="row">
        <div class="col-12 col-lg-6">
          <b-card>
            <b-card-title>
              <b-button
                class="pl-0 btn-title text-left"
                size="lg"
                variant="link"
                @click="toggleVendorDetails"
              >
                <feather-icon
                  class="text-ligth"
                  :icon="showVendorDetails ? 'ChevronDownIcon' : 'ChevronRightIcon'"
                  size="22"
                />
                <span>
                  <b>Vendor:</b>
                  <router-link
                    v-if="trip.vendor && trip.vendor._id"
                    class="ml-2"
                    :to="{ name: 'editVendor', params: { vendorId: trip.vendor._id }}"
                  >
                    {{ trip.vendor._id }}
                  </router-link>
                </span>
              </b-button>
            </b-card-title>
            <b-card-text v-show="showVendorDetails">
              <div
                v-for="(value, propertyName) in trip.vendor"
                :key="propertyName"
                class=""
              >
                <div v-if="propertyName !== '__v'">
                  <p>
                    {{ propertyName }} :
                    <b>
                      <span v-safe-html="value" />
                    </b>
                  </p>
                </div>
              </div>
            </b-card-text>
          </b-card>
        </div>

        <div class="col-12 col-lg-6">
          <b-card>
            <b-card-title>
              <b-button
                class="pl-0 btn-title text-left"
                size="lg"
                variant="link"
                @click="toggleDomainDetails"
              >
                <feather-icon
                  class="text-ligth"
                  :icon="showDomainDetails ? 'ChevronDownIcon' : 'ChevronRightIcon'"
                  size="22"
                />
                <span>
                  <b>Domain:</b>
                  <router-link
                    v-if="trip.domain && trip.domain._id"
                    class="ml-2"
                    :to="{ name: 'editDomain', params: { domainId: trip.domain._id }}"
                  >
                    {{ trip.domain._id }}
                  </router-link>
                </span>
              </b-button>
            </b-card-title>
            <b-card-text v-show="showDomainDetails">
              <div
                v-for="(value, propertyName) in trip.domain"
                :key="propertyName"
                class=""
              >
                <div v-if="propertyName !== '__v' && propertyName != 'smtp' && propertyName !== 'imap'">
                  <p>
                    {{ propertyName }} :
                    <b>
                      <span v-safe-html="value" />
                    </b>
                  </p>
                </div>
              </div>
            </b-card-text>
          </b-card>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-12">
          <b-card>
            <b-card-title>
              <b-button
                class="pl-0 btn-title text-left"
                size="lg"
                variant="link"
                @click="toggleEmails"
              >
                <feather-icon
                  class="text-ligth"
                  :icon="showEmails ? 'ChevronDownIcon' : 'ChevronRightIcon'"
                  size="22"
                />
                <span>
                  <b>Emails:</b>
                </span>
              </b-button>
            </b-card-title>
            <b-card-text v-show="showEmails">
              <app-collapse>
                <app-collapse-item
                  v-for="email in trip.emails"
                  :key="email._id"
                  :title="new Date(email.createdAt).toUTCString()"
                >
                  <div class="row">
                    <div class="col-12">
                      <b-card>
                        <b-card-text>
                          <p class="d-inline-block mr-1">
                            <span>#ID: </span>
                            <router-link
                              class=""
                              :to="{ name: 'viewEmail', params: { emailId: email._id }}"
                            >
                              {{ email._id }}
                            </router-link>
                          </p>
                          |
                          <p class="d-inline-block mx-1">
                            <span>From: </span>
                            <b-badge :variant="statusVariant(email.from)">
                              <span class="">
                                <b>
                                  {{ email.from }}
                                </b>
                              </span>
                            </b-badge>
                          </p>
                          |
                          <p class="d-inline-block mx-1">
                            <span>To: </span>
                            <b-badge :variant="statusVariant(email.to)">
                              <span class="">
                                <b>
                                  {{ email.to }}
                                </b>
                              </span>
                            </b-badge>
                          </p>
                        </b-card-text>
                      </b-card>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      <b-card>
                        <b-card-title>
                          <p><b>Text:</b></p>
                        </b-card-title>
                        <b-card-text>
                          <p
                            v-if="email && email.body && email.body.text"
                            v-safe-html="email.body.text"
                          />
                        </b-card-text>
                      </b-card>
                    </div>

                    <div class="col-4">
                      <b-card>
                        <b-card-title>
                          <p><b>Attachments:</b></p>
                        </b-card-title>
                        <b-card-text>
                          <div
                            v-for="attachment in email.attachments"
                            :key="attachment.name"
                          >
                            <a
                              :href="attachment.url"
                              target="_blank"
                            >{{ attachment.name }}</a>
                          </div>

                          <div
                            v-if="email.attachments && !email.attachments.length"
                            class="d-flex"
                          >
                            <feather-icon
                              class="text-danger d-inline-block"
                              icon="PaperclipIcon"
                              size="20"
                            />
                            <p
                              class="d-inline-block ml-1"
                            >
                              No attachments found.
                            </p>
                          </div>
                        </b-card-text>
                      </b-card>
                    </div>
                  </div>
                </app-collapse-item>
              </app-collapse>
            </b-card-text>
          </b-card>
        </div>
      </div>
    </div>

    <div
      v-else
      class="text-center"
    >
      <h3>Trip not found</h3>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable */

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormSelect,
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BOverlay,
  BBadge,
} from 'bootstrap-vue'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  name: 'EditTrip',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormSelect,
    BButton,
    BCard,
    BCardText,
    BCardTitle,
    BOverlay,
    AppCollapse,
    AppCollapseItem,
    BBadge
  },
  props: [
    'tripId',
  ],
  data() {
    return {
      trip: '',
      loading: false,
      showVendorDetails: false,
      showDomainDetails: false,
      showEmails: false,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        'info@blissfulgreece.com' : 'light-primary',
        'trips@blissfulgreece.com' : 'light-primary',
        'info@touristhopper.com' : 'light-warning',
        'trips@touristhopper.com' : 'light-warning',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    booleanVariant() {
      return status => (status === true ? 'light-primary' : 'light-warning')
    },
    vendors() {
      return (this.$store.state.app && this.$store.state.app.vendors) ? this.$store.state.app.vendors : []
    },
  },
  beforeMount() {
    this.loadVendors()
    this.loadTrip()
  },
  methods: {
    toggleVendorDetails() {
      this.showVendorDetails = !this.showVendorDetails
    },
    toggleDomainDetails() {
      this.showDomainDetails = !this.showDomainDetails
    },
    toggleEmails() {
      this.showEmails = !this.showEmails
    },
    async loadTrip() {
      const self = this

      self.loading = true
      await this.$store.dispatch('app/fetchTrip', self.tripId)
        .then(() => {
          self.trip = self.$store.state.app.trip
        })
        .catch(() => {
        })
        .finally(() => {
          self.loading = false
        })
    },
    async loadVendors() {
      const self = this

      self.loading = true
      await this.$store.dispatch('app/fetchVendors', self.tripId)
        .then(() => {})
        .catch(() => {
        })
        .finally(() => {
          self.loading = false
        })
    },
    editItem() {
      this.$router.push({ name: 'editTrip', params: { tripId: this.trip._id } })
    },
  },
}
</script>